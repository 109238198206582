import timelineStyles from './timeline.module.scss'
import React, { useEffect } from 'react';
import { Helmet } from "react-helmet"
import Img from "gatsby-image"
import { graphql } from "gatsby"

export const query = graphql`
  {
    born: file(relativePath:{eq: "born.jpg"}){
      childImageSharp{
        fluid(maxWidth:1000){
          ...GatsbyImageSharpFluid
        }
      }
    }
    elementary: file(relativePath:{eq: "elementary.jpg"}){
      childImageSharp{
        fluid(maxWidth:1000){
          ...GatsbyImageSharpFluid
        }
      }
    }
    high: file(relativePath:{eq: "high.png"}){
      childImageSharp{
        fluid(maxWidth:1000){
          ...GatsbyImageSharpFluid
        }
      }
    }
    uni: file(relativePath:{eq: "uni.jpg"}){
      childImageSharp{
        fluid(maxWidth:1000){
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default function Timeline(props) {
  const data = ((props.lang === "en") ? {
    school: ["Yoyogi University","Jingu High","Tokyo Elementary","Born"],
    location: [
      "Sunway College, No. 2, Jalan Universiti, Bandar Sunway, 47500, Selangor",
      "5-7, Jalan SS 13/4, Subang Jaya Industrial Estate, 47500 Subang Jaya, Selangor",
      "1A, Persiaran Anggerik Eria, Seksyen 31, 40460 Shah Alam, Selangor",
      "Malaysia"
    ],
    content: [
      "Taki is currently choosing a college... where shall it be?",
      "Taki sets foot into his teenage years. The essence of youth blooms within him and his peers - however the prime of his life was so soon to be over; Crossroads brought forth a reluctant parting, though every trinkle of friendship will be harbored preciously - for a lifetime.",
      "Clueless Taki enters his first education institution and tries to be social; leaves with few but precious memories.",
      "She's so cute! ...wait it's a he, ...a- a boy! Shall we call him... Taki?"
    ],
    key: ["1en", "2en", "3en", "4en"],
  } : (props.lang === "jp" ? {
    school: ["School 1","School 2","School 3","School 4"],
    location: [
      "location 1",
      "location 2",
      "location 3",
      "location 4"
    ],
    content: [
      "Content 1",
      "Content 2",
      "Content 3",
      "Content 4"
    ],
    key: ["1jp", "2jp", "3jp", "4jp"],
  } : {
    school: ["LangError","LangError","LangError"],
    location: ["LangError","LangError","LangError"],
    content: ["LangError","LangError","LangError"],
    key: ["123456","234567","345678","456789"],
  }))
  data.date = ["2020", "2015", "2009", "2003"];
  data.img = [props.data.uni.childImageSharp.fluid, props.data.high.childImageSharp.fluid, props.data.elementary.childImageSharp.fluid, props.data.born.childImageSharp.fluid];
  data.locationLink = ["https://www.google.com/maps/place/Sunway+College/@3.0676015,101.602248,15z/data=!4m5!3m4!1s0x0:0x39ae4742dbef6c2b!8m2!3d3.0681157!4d101.6039217", "https://www.google.com/maps/place/UCSI+International+School+-+Subang+Jaya/@3.0696918,101.5967769,15z/data=!4m5!3m4!1s0x0:0xbc9f0bf78e83c545!8m2!3d3.0696918!4d101.5967769", "https://www.google.com/maps/place/SJK(C)+Chung+Hua+Kota+Kemuning/@2.9885486,101.532853,15z/data=!4m2!3m1!1s0x0:0xe979b4d1550ee483?sa=X&ved=2ahUKEwjWsqOQjpvtAhXG63MBHdvNAXQQ_BIwDHoECBEQBQ", ""];
  const dataArray = []
  for (let i = 0; i < data.date.length; i++){
    let obj = {}
    for (let key in data) {
      obj[key] = data[key][i]
    }
    dataArray.push(obj)
  }
  useEffect(() => {
    let elements = document.getElementsByClassName(timelineStyles.contentWrapper)
    let observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if(entry.isIntersecting){
          entry.target.classList.add(timelineStyles.fadeInElement)
        }
      })
    }, {
      rootMargin: "-12.5%",
    })
    for (let i = 0; i < elements.length; i++){
      observer.observe(elements[i])
    }
    return (() => {
      for (let i = 0; i < elements.length; i++){
        observer.unobserve(elements[i])
      }
    })
  })
  return (
    <div id={timelineStyles.containerWrapper}>
      <Helmet>
        <title>
          {props.lang === "en" ? "Ming's history ~" : ""}
          {props.lang === "jp" ? "タイムライン" : ""}
        </title>
        <meta name="description" content="This is my personal timeline! Feel free to know more about me, though, only very superficially.."/>
      </Helmet>
      {dataArray.map(function (data) {
        let attr;
        if (data.locationLink !== "") {
          attr = {
            target: "_blank",
            rel: "noopener noreferrer",
            xlinkHref: `${data.locationLink}`
          }
        }
        return(
          <div className={timelineStyles.contentWrapper} key={data.key}>
            <div className={timelineStyles.container}>
              <div className={timelineStyles.dynamicContainer}>
                <div className={timelineStyles.dateContainer}>
                  <h2 className={timelineStyles.date}>{data.date}</h2>
                </div>
                <div className={timelineStyles.leftContainer}>
                  <div className={timelineStyles.vertLine} />
                  <div className={timelineStyles.horiLine} />
                </div>
              </div>
              <div className={timelineStyles.rightContainer}>
                <div className={timelineStyles.contentContainer}>
                  <div className={timelineStyles.contentImageContainer}>
                    <Img fluid={data.img} className={timelineStyles.contentImage}/>
                  </div>
                  <h1 className={timelineStyles.contentHeader}>{data.school}</h1>
                  <div className={timelineStyles.locationContainer}>
                    <div className={timelineStyles.svgContainer}>
                      <svg className="hover-element" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke="white" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                        <a {...attr} >
                          <path fill="#eb8a0c" d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                          <circle fill="#eb8a0c" cx="12" cy="10" r="3"></circle>
                        </a>
                      </svg>
                    </div>
                    <a {...attr} className={timelineStyles.locationText + " hover-element"}>{data.location}</a>
                  </div>
                  <div className={timelineStyles.contentDash} />
                  <div className={timelineStyles.contentTextContainer}>
                    <p className={timelineStyles.content}>{data.content}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}